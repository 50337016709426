import React, { useContext } from "react";
import {
  StyledFooterLayer,
  StyledMenuWrapper,
  StyledMenuItem,
  StyledFooter,
  StyledMenuList,
  StyledMenuTitle,
  StyledExternalLink,
  StyledCompany,
  StyledCopyright,
  StyledCopyrightWrapper,
  StyledLogoAndMenuWrapper,
  StyledFooterLogo,
  StyledRWDGroup,
  StyledSingleMenuGroup,
  StyledMenuGroup,
} from "./styles";
import { Box } from "@mui/material";
import { menuList } from "../../../data/menu-data";
import { Link } from "gatsby";
import FbIcon from "../../Icon/FbIcon";
import YtIcon from "../../Icon/YtIcon";
import IgIcon from "../../Icon/IgIcon";
import VectorIcon from "../../Icon/VectorIcon";
import { TransitionContext } from "../../Context/TransitionContext";
import { navigate } from "@reach/router";

const Footer = () => {
  const { updatePageTransition } = useContext(TransitionContext);

  // 延遲1秒先做過場動畫
  const handleClick = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    updatePageTransition(true);
    console.log("event.target.href", event.target.href);
    setTimeout(() => {
      navigate(event.target.href || "/");
    }, 1000);
  };

  return (
    <StyledFooter>
      <StyledFooterLayer>
        <StyledLogoAndMenuWrapper>
          <StyledFooterLogo>
            <img src='/images/logo.webp' width='126' height='42' alt='logo' />
          </StyledFooterLogo>
          <StyledMenuGroup>
            <StyledMenuList>
              {menuList.map((nav) => (
                <StyledSingleMenuGroup key={nav.id}>
                  <StyledMenuTitle>{nav.name}</StyledMenuTitle>
                  <StyledMenuWrapper>
                    {nav.children &&
                      nav.children.map((children) => (
                        <StyledMenuItem key={children.id}>
                          <Link
                            to={children.path}
                            title={children.name}
                            onClick={handleClick}
                          >
                            {children.name}
                          </Link>
                        </StyledMenuItem>
                      ))}
                  </StyledMenuWrapper>
                </StyledSingleMenuGroup>
              ))}
            </StyledMenuList>
            <Box>
              <StyledMenuTitle>聯絡我們</StyledMenuTitle>
              <StyledMenuWrapper
                sx={{ minHeight: "auto", marginBottom: "44px" }}
              >
                <StyledMenuItem>
                  聯絡電話：<a href='tel:0221755166'>02-21755166</a>
                </StyledMenuItem>
                <StyledMenuItem>
                  服務時間：週一至週五 08:30~18:00
                </StyledMenuItem>
              </StyledMenuWrapper>
            </Box>
          </StyledMenuGroup>
        </StyledLogoAndMenuWrapper>

        <StyledExternalLink>
          <StyledRWDGroup>
            <a
              href='https://www.facebook.com/tw.amway'
              target='_blank'
              rel='noopener noreferrer'
              title='facebook'
            >
              <FbIcon />
            </a>
            <a
              href='https://www.instagram.com/amwaytw/'
              target='_blank'
              rel='noopener noreferrer'
              title='instagram'
            >
              <IgIcon />
            </a>
            <a
              href='https://www.youtube.com/@amway_taiwan'
              target='_blank'
              rel='noopener noreferrer'
              title='youtube'
            >
              <YtIcon />
            </a>
          </StyledRWDGroup>
          <Link to='/' onClick={handleClick}>
            <VectorIcon />
          </Link>
        </StyledExternalLink>
        <StyledCopyrightWrapper>
          <StyledCompany>安麗日用品股份有限公司</StyledCompany>
          <StyledCopyright>
            © 2024 Amway Taiwan Company Limited. All Rights Reserved. |
            <a
              href='https://shop.amway.com.tw/privacy-policy'
              target='_blank'
              rel='noopener noreferrer'
              title='隱私權政策'
            >
              隱私權政策
            </a>
          </StyledCopyright>
        </StyledCopyrightWrapper>
      </StyledFooterLayer>
    </StyledFooter>
  );
};

export default Footer;
